<div class="preloader" *ngIf="isLoading">
    <div class="loading">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div> 
        <div  ><h2 class="title">STBI Informatique </h2>  </div> 
    </div>
    
</div>